import Button from "antd-mobile/es/components/button";
import NavBar from "antd-mobile/es/components/nav-bar";
import Popup from "antd-mobile/es/components/popup";
import clsx from "clsx";
import AppLayout from "~/components/layouts/AppLayout";

type PopupWithNavbarProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  actions?: any[];
  className?: string;
  bodyClassName?: string;
  navbarProps?: any;
  shadow?: boolean;
  position?: "right" | "left" | "top" | "bottom";
  headerClass?: string;
  layoutClassName?: string;
};

export default function PopupWithNavbar({
  open,
  onClose,
  children,
  title,
  subtitle,
  actions,
  className,
  bodyClassName,
  navbarProps,
  headerClass,
  shadow = true,
  position = "right",
  layoutClassName,
  paddingClass,
}: PopupWithNavbarProps) {
  const navbar = (
    <NavBar
      className="nav-right-null relative bg-theme-300"
      style={{ "--height": "4rem" }}
      back={true}
      backArrow={true}
      right={null}
      onBack={onClose}
      {...navbarProps}
    >
      <div className={clsx("flex h-full flex-col justify-center")}>
        <h1 className="truncate">{title}</h1>
        <h3 className="truncate text-base font-semibold leading-6 text-gray-900">{subtitle}</h3>
      </div>
    </NavBar>
  );
  return (
    <Popup
      visible={open}
      onMaskClick={onClose}
      position={position}
      bodyClassName={clsx(bodyClassName ? bodyClassName : "w-full sm:w-[32rem] overflow-y-auto")}
      destroyOnClose={true}
      className={className}
    >
      <AppLayout shadow={shadow} navbar={navbar} headerClass={headerClass} layoutClassName={clsx("h-full", layoutClassName)}>
        <div className={clsx("root-page-layout relative flex flex-1 flex-col overflow-y-auto")}>
          <div className="flex h-full flex-1 flex-col">{children}</div>
        </div>
        {actions && (
          <div className="sticky bottom-0 z-10 flex gap-2 bg-white p-2">
            {actions.map((action, index) => {
              return (
                <Button {...action} className={clsx(action.className ?? "flex-1")} key={action.key ?? index}>
                  {action.text}
                </Button>
              );
            })}
          </div>
        )}
      </AppLayout>
    </Popup>
  );
}
